.update-notification {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  max-width: 100%;
  height: auto;
  min-height: 3rem;
  line-height: 1.5em;
  padding: 1rem 1.5625rem;
  font-size: 1.1rem;
  clear: both;
  word-break: keep-all;
  background-color: #323232;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin: 2%;
}

@media screen and (max-width: 1140px) {
  .update-notification {
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;
    max-width: 100%;
    height: auto;
    min-height: 3rem;
    line-height: 1.5em;
    padding: 1rem 1.5625rem;
    font-size: 1.1rem;
    clear: both;
    word-break: keep-all;
    background-color: #323232;
    color: #ffffff;
    border-radius: 3px;
    border: none;
    margin: 5%;
  }
}
